<template>
  <UpdateForm
    :data="data"
    :columnsProjectWork="columnsProjectWork"
    :columnsProjectDump="columnsProjectDump"
    :columnsConstructionSection="columnsConstructionSection"
    :columnsCompany="columnsCompany"
    v-on:handleVehicle="handleVehicle"
    v-on:onsubmit="onSubmit"
  />
</template>

<script>
import _ from "lodash";
import { Toast, Notify } from 'vant';
import UpdateForm from "./components/UpdateForm.vue";

export default {
  components: { UpdateForm },
  data() {
    return {
      data: {},
      params: {},
      columnsProjectWork: [],
      columnsProjectDump: [],
      columnsConstructionSection: [],
      columnsCompany: [],
      detail: {},
    };
  },
  mounted() {
    const { $route } = this;

    let project = JSON.parse(sessionStorage.getItem('project')) || [];
    if (project.length > 0) {
      this.columnsProject = project;
      this.columnsProjectWork = JSON.parse(sessionStorage.getItem('projectWork')) || [];
      this.columnsProjectDump = JSON.parse(sessionStorage.getItem('projectDump')) || [];
    }
    else this.loadProject();

    this.loadView({ id: $route.query.id });
  },
  methods: {
    loadView(params) {
      this.$request.queryWaybillDetail({ ...params }, response => {
        if (response.status === 200) {
          const { license, alias, driverName, loadSiteId, dumpSiteId, constructionSectionId, vehicleCompanyId } = response.data;
          this.data = {
            license,
            alias,
            driverName,
            loadSiteId,
            dumpSiteId,
            constructionSectionId,
            vehicleCompanyId
          };
          this.loadProject();
          this.loadConstructionSection({ projectId: loadSiteId });
          
          if (vehicleCompanyId) {
            this.loadCompany();
          }
        }
      })
    },  
    handleVehicle({ license, alias }) {
      this.loadVehicle({ license, alias });
    },
    loadVehicle({ license, alias }) {
      const initVehicle = result => {
        if (result) {
          const { id, license, alias } = result;
          this.data = { ...this.data, license, alias };

          this.$request.queryDriver({ id: result.id }, response =>{
            if (response.status == 200) {
              if (response.data.driver) {
                const { id, driverName, userId } = response.data.driver;
                this.params.driverId = id;
                this.params.driverName = driverName;
                this.params.driverUserId = userId;
                this.data = { ...this.data, driverName };
              }
            }
          });
        } else {
          this.data = { ...this.data, license, alias };
          this.params.driverId = '';
          this.params.driverName = '';
          this.params.driverUserId = '';
          this.loadCompany();
        }
      }

      if (!sessionStorage.vehicle) {
        this.$request.queryVehicle({}, response => {
          if (response.status === 200) {
            const result = response.data;
            sessionStorage.vehicle = JSON.stringify(result);

            let data = result.filter(item => item.license == license || item.alias == alias);
            initVehicle(data[0]);
          }
        })
      }else{
        const result = JSON.parse(sessionStorage.getItem('vehicle')) || [];
        let data = result.filter(item => item.license == license || item.alias == alias);
        initVehicle(data[0]);
      }
    },
    loadProject() {
      Toast.loading({
        message: '加载中',
        forbidClick: true,
        duration: 0,
      });
      this.$request.queryProject({}, response => {
        if (response.status === 200) {
          const result = response.data;
          let projectName = '';

          let columnsProjectWork = [], columnsProjectDump = [];
          result.map(item => {
            item.text = item.name;

            if (item.id == this.data.projectId) {
              projectName = item.name;
            }

            if (item.type == 3 || item.type == 2) {
              columnsProjectWork.push(item);
            }

            if (item.type == 1 || item.type == 2) {
              columnsProjectDump.push(item);
            }
          })
          sessionStorage.project = JSON.stringify(result);
          sessionStorage.projectWork = JSON.stringify(columnsProjectWork);
          sessionStorage.projectDump = JSON.stringify(columnsProjectDump);
          this.columnsProject = result;
          this.columnsProjectWork = columnsProjectWork;
          this.columnsProjectDump = columnsProjectDump;
          Toast.clear();
        }
      })
    },
    loadConstructionSection(params) {
      this.$request.queryConstructionSection({ ...params }, response => {
        if (response.status === 200) {
          const result = response.data;
          result.map(item => {
            item.text = item.name;
          })
          this.columnsConstructionSection = result;
        }
      })
    },
    loadCompany(params) {
      this.$request.queryExternalCompany({ ...params }, response => {
        if (response.status === 200) {
          const result = response.data;
          result.map(item => {
            item.text = item.name;
          })
          this.columnsCompany = result;
        }
      })
    },
    onSubmit(values) {
      const {
        $store,
        $route,
        data: {
          startImg,
          companyId,
          createUser,
          driverId,
          driverName
        },
      } = this;
      values.id = $route.query.id*1;
      
      values = { ...values, startImg, companyId, createUser, driverId, driverName };
      
      _.forIn(values, (v, k) => {
        if (!v) delete values[k];
      })

      if ($store.state.loading) return;
      $store.commit('setLoading', { loading: true });

      this.$request.updateWaybill({ ...values }, response => {
        if (response.status === 200) {
          const result = response.data;
          $store.commit('setLoading', { loading: false });
          
          if (result.code === 1) {
            Notify({ type: 'success', message: '保存成功' });
            this.$router.replace({ path: '/complate' });
          }else{
            Notify({ type: 'warning', message: result.msg });
          }
        }
      })
    },
  },
}
</script>
