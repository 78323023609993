<template>
  <van-form @submit="onSubmit">
    <van-field
      v-model="license"
      label="车牌号"
      placeholder="请输入车牌号"
      maxlength="7"
    />
    <van-field
      v-model="alias"
      name="alias"
      label="大号"
    />

    <van-field
      v-model="driverName"
      label="司机"
      placeholder="请输入司机"
    />

    <van-field
      readonly
      clickable
      name="loadSiteId"
      v-model="loadSite"
      label="装点名称"
      placeholder="点击选择装点名称"
      is-link
      arrow-direction="down"
      required
      :rules="[{ required: true, message: '装点名称不能为空' }]"
      @click="showLoadSite = true"
    />
    <van-popup
      v-model:show="showLoadSite"
      position="bottom"
    >
      <Picker
        :columns="columnsProjectWork"
        v-on:change="value => columnActiveWork = value"
      />

      <van-cell>
        <van-button
          round
          block
          type="primary"
          @click="onConfirmWorkSite"
        >
          确定
        </van-button>
      </van-cell>
    </van-popup>

    <van-field
      name="radio"
      label="是否短驳"
    >
      <template #input>
        <van-radio-group
          v-model="radio"
          direction="horizontal"
        >
          <van-radio
            name="1"
            shape="square"
          >是</van-radio>
          <van-radio
            name="2"
            shape="square"
          >否</van-radio>
        </van-radio-group>
      </template>
    </van-field>

    <van-field
      v-if="disabled"
      v-model="dumpSite"
      label="卸点名称"
      placeholder="请输入卸点名称"
    />

    <van-field
      v-else-if="!disabled"
      readonly
      clickable
      name="dumpSiteId"
      v-model="dumpSite"
      label="卸点名称"
      placeholder="点击选择卸点名称"
      is-link
      arrow-direction="down"
      required
      :rules="[{ required: true, message: '卸点名称不能为空' }]"
      @click="showDumpSite = true"
    />
    <van-popup
      v-model:show="showDumpSite"
      position="bottom"
    >
      <Picker
        :columns="columnsProjectDump"
        v-on:change="value => columnActiveDump = value"
      />

      <van-cell>
        <van-button
          round
          block
          type="primary"
          @click="onConfirmDumpSite"
        >
          确定
        </van-button>
      </van-cell>
    </van-popup>

    <van-field
      readonly
      clickable
      name="constructionSectionId"
      v-model="constructionSection"
      label="施工段"
      placeholder="点击选择施工段"
      required
      is-link
      arrow-direction="down"
      :rules="[{ required: true, message: '施工段不能为空' }]"
      @click="showConstructionSection = true"
    />
    <van-popup
      v-model:show="showConstructionSection"
      position="bottom"
    >
      <Picker
        :columns="columnsConstructionSection"
        v-on:change="value => columnActiveConstruction = value"
      />

      <van-cell>
        <van-button
          round
          block
          type="primary"
          @click="onConfirmConstructionSection"
        >
          确定
        </van-button>
      </van-cell>
    </van-popup>

    <van-field
      v-if="!alias"
      readonly
      clickable
      name="companyId"
      v-model="company"
      label="外包公司"
      placeholder="点击选择公司名称"
      required
      is-link
      arrow-direction="down"
      :rules="[{ required: true, message: '公司名称不能为空' }]"
      @click="showCompany = true"
    />
    <van-popup
      v-model:show="showCompany"
      position="bottom"
    >
      <Picker
        :columns="columnsCompany"
        v-on:change="value => columnActiveCompany = value"
      />

      <van-cell>
        <van-button
          round
          block
          type="primary"
          @click="onConfirmCompany"
        >
          确定
        </van-button>
      </van-cell>
    </van-popup>

    <div style="margin: 16px;">
      <van-button
        :loading="$store.state.loading"
        round
        block
        type="primary"
        native-type="submit"
      >
        提交
      </van-button>
    </div>
  </van-form>
</template>

<script>
import Picker from "../../../components/Picker.vue";

export default {
  props: [
    "data",
    "columnsProjectWork",
    "columnsProjectDump",
    "columnsConstructionSection",
    "columnsCompany"
  ],
  components: { Picker },
  data() {
    return {
      license: "",
      alias: "",
      driverName: "",
      radio: "2",
      disabled: false,
      readyLicense: false,

      loadSite: "",
      loadSiteId: undefined,
      showLoadSite: false,
      columnActiveWork: {},

      dumpSite: "",
      dumpSiteId: undefined,
      showDumpSite: false,
      columnActiveDump: {},

      constructionSection: "",
      constructionSectionId: undefined,
      showConstructionSection: false,
      columnActiveConstruction: {},

      company: "",
      vehicleCompanyId: undefined,
      showCompany: false,
      columnActiveCompany: {}
    };
  },
  mounted() {
    const { $watch, $emit, initValues } = this;

    $watch("data", newValue => {
      const {
        license,
        alias,
        driverName,
        loadSiteId,
        dumpSiteId,
        constructionSectionId,
        vehicleCompanyId
      } = newValue;

      if (loadSiteId && loadSiteId == dumpSiteId) {
        this.radio = "1";
        this.disabled = true;
      }

      if (this.license == newValue.license) {
        this.readyLicense = true;
      }

      this.license = license;
      this.alias = alias;
      this.driverName = driverName;
      this.dumpSiteId = dumpSiteId;
      this.loadSiteId = loadSiteId;
      this.constructionSectionId = constructionSectionId;
      this.vehicleCompanyId = vehicleCompanyId;
    });

    $watch("license", newValue => {
      const { readyLicense } = this;
      if (newValue && newValue.length == 7 && !readyLicense) {
        $emit("handleVehicle", { license: newValue });
      }else{
        this.readyLicense = false;
      }
    });

    $watch("alias", newValue => {
      if (newValue) {
        setTimeout(() => {
          const { alias, readyAlias } = this;
          if (newValue == alias) {
            $emit("handleVehicle", { alias: newValue });
          }
        }, 2000);
      }
    });

    $watch("columnsProjectWork", newValue => {
      const { loadSiteId } = this;
      initValues(newValue, loadSiteId, "loadSiteId", "loadSite");
    });

    $watch("columnsProjectDump", newValue => {
      const { dumpSiteId } = this;
      initValues(newValue, dumpSiteId, "dumpSiteId", "dumpSite");
    });

    $watch("columnsConstructionSection", newValue => {
      const { constructionSectionId } = this;
      initValues(
        newValue,
        constructionSectionId,
        "constructionSectionId",
        "constructionSection"
      );
    });

    $watch("columnsCompany", newValue => {
      const { vehicleCompanyId } = this;
      initValues(newValue, vehicleCompanyId, "vehicleCompanyId", "company");
    });

    $watch("radio", newValue => {
      if (newValue == 1) {
        this.disabled = true;
        this.dumpSite = this.columnActiveWork.name;
        this.dumpSiteId = this.columnActiveWork.id;
      } else if (newValue == 2) {
        this.disabled = false;
        this.dumpSite = "";
        this.dumpSiteId = undefined;
      }
    });
  },
  methods: {
    initValues(ary, id, idName, name) {
      if (id) {
        this[idName] = id;
        ary.map(item => {
          if (item.id == id) {
            this[name] = item.name;
          }
        });
      }
    },
    onConfirmWorkSite() {
      const {
        columnActiveWork: { id, text }
      } = this;
      this.workSite = text;
      this.loadSiteId = id;
      this.showLoadSite = false;
    },
    onConfirmDumpSite() {
      const {
        columnActiveDump: { id, text }
      } = this;
      this.dumpSite = text;
      this.dumpSiteId = id;
      this.showDumpSite = false;
    },
    onConfirmConstructionSection() {
      const {
        columnActiveConstruction: { id, text }
      } = this;
      this.constructionSection = text;
      this.constructionSectionId = id;
      this.showConstructionSection = false;
    },
    onConfirmCompany() {
      const {
        columnActiveCompany: { id, text }
      } = this;
      this.company = text;
      this.vehicleCompanyId = id;
      this.showCompany = false;
    },
    onSubmit() {
      const {
        license,
        alias,
        driverName,
        loadSiteId,
        dumpSiteId,
        constructionSectionId,
        vehicleCompanyId
      } = this;

      this.$emit("onsubmit", {
        license,
        alias,
        driverName,
        projectId: loadSiteId,
        loadSiteId,
        dumpSiteId,
        constructionSectionId,
        vehicleCompanyId
      });
    }
  }
};
</script>
